<template>
    <div id="app">
        <div class="flex"
             :class="containerClass">
            <div class="slide-container">
                <brand-logo v-if="showLogo"
                            :unit-text="unit"
                            :institution-text="institution"/>
                <post-slides @images-loaded="onPostSlidesImagesLoaded"
                             :slides-url="slidesUrl"
                             :slides-duration="slidesDuration"
                             :slides-transition="slidesTransition"
                             :slides-on-wordpress="slidesOnWordpress"
                             :slides-refresh-time="slidesRefreshTime"
                             :show-post-title="slidesShowTitle"
                             :show-post-excerpt="slidesShowExcerpt"/>
            </div>

            <div class="side-bar"
                 v-if="hasSideBar">
                <digital-clock lightdark="dark"
                               v-if="showClock"/>
                <weather-display :refreshTime="weatherRefresh"
                                 :latitude="weatherLatitude"
                                 :longitude="weatherLongitude"/>
                <bus-schedule :stops="busStops"
                              :refreshTime="busScheduleRefreshTime"
                              :per-list="busArrivalsPerList"
                              :arriving-soon-minutes="busArrivingSoon"
                              :schedule-label="busScheduleLabel"/>
            </div>
        </div>

        <div class="preloader" :class="{ loaded: isLoaded }">
          <LoadingAnim />
        </div>
    </div>
</template>

<script>
    import LoadingAnim from '@/components/LoadingAnim.vue';
    import BrandLogo from '@/components/BrandLogo.vue';
    import PostSlides from '@/components/PostSlides.vue';
    import BusSchedule from '@/components/BusSchedule.vue';
    import WeatherDisplay from '@/components/WeatherDisplay.vue';
    import DigitalClock from '@/components/DigitalClock.vue';
    import { mapOptions } from '@/config';
    export default {
        name: 'app',
        data() {
            return {
                isLoaded: false
            };
        },
        components: {
            PostSlides,
            BusSchedule,
            WeatherDisplay,
            BrandLogo,
            DigitalClock,
            LoadingAnim
        },
        computed: {
            ...mapOptions([
                'slidesDuration',
                'slidesUrl',
                'slidesTransition',
                'slidesOnWordpress',
                'slidesRefreshTime',
                'busStops',
                'busScheduleRefreshTime',
                'busArrivalsPerList',
                'busArrivingSoon',
                'sidebarLocation',
                'weatherRefresh',
                'weatherLatitude',
                'weatherLongitude',
                'showClock',
                'busScheduleLabel',
                'showLogo',
                'unit',
                'institution',
                'slidesShowTitle',
                'slidesShowExcerpt'
            ]),
            containerClass() {
                return `side-bar-${this.sidebarLocation}`;
            },
            hasSideBar() {
                return this.sidebarLocation != 'none';
            },

        },
        methods: {
            onPostSlidesImagesLoaded() {
                this.isLoaded = true;
            }
        }
    };
</script>

<style lang="scss">
    /* Verlag Black */
    @font-face {
        font-family: "Verlag-Black";
        src: url("./assets/VerlagBlack.eot");
        src: url("./assets/VerlagBlack.eot") format("embedded-opentype"), url("./assets/VerlagBlack.woff2") format("woff2"), url("./assets/VerlagBlack.woff") format("woff"), url("./assets/VerlagBlack.ttf") format("truetype"), url("./assets/VerlagBlack.svg#VerlagBlack") format("svg");
    }

    /* Verlag Book */
    @font-face {
        font-family: "Verlag-Book";
        src: url("./assets/VerlagBook.eot");
        src: url("./assets/VerlagBook.eot") format("embedded-opentype"), url("./assets/VerlagBook.woff2") format("woff2"), url("./assets/VerlagBook.woff") format("woff"), url("./assets/VerlagBook.ttf") format("truetype"), url("./assets/VerlagBook.svg#VerlagBook") format("svg");
    }

    @font-face {
        font-family: "Verlag-Light";
        src: url("./assets/Verlag-Light.eot");
        src: url("./assets/Verlag-Light.eot") format("embedded-opentype"), url("./assets/Verlag-Light.woff") format("woff"), url("./assets/Verlag-Light.ttf") format("truetype"), url("./assets/Verlag-Light.svg#Verlag-Light") format("svg");
    }

    body {
        margin: 0px;
    }

    #app {
        font-family: "Verlag-Book", "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin: 0px;
        background: #000;
        height: 100vh;
        overflow: hidden;
    }

    .flex {
        display: flex;
        height: 100%;
    }


    .side-bar {
        min-width: 420px;
        display: flex;
        flex-direction: column;
        position: relative;
        flex-basis: 25%;
        justify-content: flex-start;
        color: #000;
        background: #fff;
        box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.2);
    }

    .slide-container {
        flex-grow: 1;
        position: relative;
    }

    .side-bar-none {
        .slide-container {
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .side-bar {
            display: none;
        }
    }

    .side-bar-left {
        &.flex {
            flex-direction: row-reverse
        }
        .side-bar {
            border-right: 4px solid #c5050c;
        }
    }

    .side-bar-right {
        &.flex {
            flex-direction: row
        }

        .side-bar {
            border-left: 4px solid #c5050c;
        }
    }



    @media only screen and (max-width: 1300px) {
        .side-bar {
            min-width: 360px;
        }
    }

    .preloader {
        position: fixed;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 100%;
        z-index: 20;
        background: #fff;
    }

    .preloader.loaded {
        animation-name: slidefade;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-delay: 0.5s;
    }

    h2 {
        font-family: "Verlag-black";
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 1.1rem;
    }

    @keyframes slidefade {
        0% {
            opacity: 1;
        }

        50% {
            transform: translateX(100%);
            opacity: 1;
        }

        100% {
            opacity: 0;
            transform: translateX(100%);
        }
    }
</style>
