<template>
    <div class="wp-slides"
         @click="forceNextPost">
        <transition-group :name="slidesTransition" tag="ul" mode="in-out">
            <li v-for="post in currentPost"
                :key="post.id"
                :class="{ 'include-copy': showSlideCopy }">
                <div class="slide-copy" v-if="showSlideCopy">
                    <h1 v-html="post.title"
                        v-if="showPostTitle"></h1>
                    <div class="excerpt" 
                         v-html="post.excerpt"
                         v-if="showPostExcerpt"></div>
                </div>
                <img :src="post.imageUrl" />
                <div class="videofile" v-if="post.hasVideo">
                    <video v-if="post.videoFile" width="1920" height="1080" class="slideVideo" id="vid" ref="videoRef" muted="muted" autoplay="true">
                        <source :src="post.videoFile" type="video/mp4">

                        Your browser does not support the video tag.
                    </video>
                </div>

            </li>
        </transition-group>

        <div class="prefetch" aria-hidden="true">
            <div v-for="post of posts"
                 :key="post.id"
                 v-images-loaded:on.done="imageProgress">
                <img :src="post.imageUrl" />
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import imagesLoaded from '@/utils/imagesLoadedDirective';
    import _ from 'lodash';

    export default {
        name: "PostSlides",
        emits: ['images-loaded'],
        props: {
            slidesUrl: {
                type: String,
                required: true
            },
            slidesTransition: {
                type: String,
                required: true
            },
            slidesDuration: {
                type: Number,
                required: true,
            },
            slidesRefreshTime: {
                type: Number,
                required: true,
            },
            slidesOnWordpress: {
                type: Boolean,
                required: false,
                default: true
            },
            showPostTitle: {
                type: Boolean,
                required: false,
                default: false
            },
            showPostExcerpt: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                posts: [],
                errors: [],
                postindex: 0,
                currentPost: null,
                imagesLoading: 0,
                debouncedScheduleNextPost: null,
            };
        },
        directives: {
            imagesLoaded
        },
        created() {
            this.refreshSlides()
            
        },
        computed: {
            slidesRefreshTimeInMs() {
                return this.slidesRefreshTime * 60 * 1000;
            },
            slidesFullUrl() {
                return this.slidesOnWordpress ? this.slidesUrl + `/wp-json/wp/v2/posts/?_embed&per_page=60` : this.slidesUrl
            },
            showSlideCopy() {
                return this.showPostTitle || this.showPostExcerpt;
            }
        },
        methods: {
            refreshSlides() {
                this.loadSlides()
                    .then(() => {
                        this.loadNextPost();
                    })

                setInterval(() => {
                    this.loadSlides()
                        .then(() => {
                            this.loadNextPost();
                        })

                }, this.slidesRefreshTimeInMs)
            },
            loadSlides() {
                //retrieve posts

                return axios
                    .get(this.slidesFullUrl)
                    .then(response => {
                        const postsFromService = response.data;

                        // filter out any that do not have media (.hasImage == false)
                        this.posts = postsFromService.map(this.mapPostToSlide)
                            .filter(post => post.hasImage == true)
                    })
                    .catch(e => {
                        this.errors.push(e);
                    });
            },
            imageProgress() {
                this.imagesLoading = this.imagesLoading + 1;

                const loadedImages = this.imagesLoading;
                const availableImages = this.posts.length;

                if (loadedImages == availableImages) {
                    this.imagesLoaded = true;
                    this.$emit("images-loaded", true);
                }

            },

            /**
             * Flattens and maps a Wordpress post to a slide for display
             * @param {Object} post Wordpress post
             */
            mapPostToSlide(post) {

                let acf_video = '';
                let duration = this.slidesDuration;

                if (typeof post.acf != 'undefined') {
                    // post comes from a Wordpress with Advanced Custom Forms (ACF) plugin

                    if (typeof post.acf.slide_video_file != 'undefined')
                        acf_video = post.acf.slide_video_file;

                    if (typeof post.acf.slide_duration != 'undefined' && Number.isInteger(post.acf.slide_duration))
                        duration = parseInt(post.acf.slide_duration);
                }

                return {
                    id: post.id,
                    hasImage: (typeof post._embedded != 'undefined' && typeof post._embedded['wp:featuredmedia'] != 'undefined'),
                    title: post.title.rendered,
                    excerpt: post.excerpt.rendered,
                    duration: duration,
                    videoFile: acf_video,
                    hasVideo: (acf_video != ''),
                    imageUrl: post._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url
                }
            },

            /**
             * Gets the next post to be displayed, and schedules it for display.
             * */
            loadNextPost() {
                if (this.postindex == this.posts.length - 1)
                    this.postindex = -1;

                this.postindex++;

                const newPost = this.posts[this.postindex];

                this.currentPost = [newPost];

                this.scheduleNextPost(newPost.duration * 1000);

            },

            /**
             * Allow user to manually click through posts
             * */
            forceNextPost() {
                if (this.debouncedScheduleNextPost) {
                    this.debouncedScheduleNextPost.flush()
                } else {
                    this.scheduleNextPost(1)
                }
            },

            /**
             * Schedule an attempt to load the next post
             *
             * @function scheduleNextPost
             * @param {Number} msToWait Number of milliseconds to wait.
             */
            scheduleNextPost(msToWait) {
                if (this.debouncedScheduleNextPost) {
                    console.debug('ignoring schedule next post request since already pending')
                    return
                }
                // keep track of our debounced function so we can flush it later if needed, but if we've been called already
                // then clear our track so we dont try and flush it later.
                this.debouncedScheduleNextPost = _.debounce(() => {
                    this.debouncedScheduleNextPost = null
                    this.loadNextPost()
                }, msToWait)
                this.debouncedScheduleNextPost()
            },

        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    .slideVideo {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 20;
    }

    .wp-slides {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow: hidden;

        .prefetch {
            opacity: 0;
        }

        ul {
            margin: 0px;
            padding: 0px;
            list-style: none;

            li:nth-child(1) {
                z-index: 10;
            }

            li:nth-child(2) {
                z-index: 9;
            }

            li:nth-child(3) {
                z-index: 8;
            }

            li:nth-child(4) {
                z-index: 7;
            }

            li:nth-child(5) {
                z-index: 6;
            }

            li:nth-child(6) {
                z-index: 5;
            }

            li:nth-child(7) {
                z-index: 4;
            }

            li:nth-child(8) {
                z-index: 3;
            }

            li:nth-child(9) {
                z-index: 2;
            }

            li:nth-child(10) {
                z-index: 1;
            }

            li.include-copy:before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                height: 30%;
                z-index: 1;
                background: -moz-linear-gradient( top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100% ); /* FF3.6-15 */
                background: -webkit-linear-gradient( top, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100% ); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient( to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100% ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
            }

            li.include-copy:after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0px;
                left: 0px;
                right: 0px;
                height: 50%;
                z-index: 1;
                background: -moz-linear-gradient( top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100% ); /* FF3.6-15 */
                background: -webkit-linear-gradient( top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100% ); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient( to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100% ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#bf000000',GradientType=0 ); /* IE6-9 */
            }

            li {
                display: block;
                position: absolute;
                margin: 0px;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                z-index: 10;

                .slide-copy {
                    font-family: "Verlag-light";
                    position: absolute;
                    bottom: 2rem;
                    left: 2rem;
                    z-index: 3;
                    border-left: 6px solid #c5050c;
                    padding-left: 2rem;
                    padding-right: 2rem;

                    h1 {
                        font-family: "Verlag-Black";
                        margin: 0px;
                        display: block;
                        color: #fff;
                        z-index: 6;
                        font-size: 3rem;
                        text-align: left;
                        line-height: 1;
                        text-transform: uppercase;
                    }

                    .excerpt {
                        margin: 0px;
                        display: block;
                        color: #fff;
                        z-index: 6;
                        font-size: 2rem;
                        text-align: left;
                        font-weight: 300;
                        line-height: 1;
                    }
                }

                img {
                    object-position: 50% 50%;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    @media only screen and (max-width: 1300px) {
        .wp-slides ul li .slide-copy h1 {
            font-size: 2rem;
        }

        .wp-slides ul li .slide-copy .excerpt {
            font-size: 1.5rem;
        }
    }

    .spin-out-enter-active {
        transition: all 1.5s ease;
        transition-delay: 0.5s;
    }

    .spin-out-leave-active {
        transition: all 0.7s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .spin-out-enter-from
    /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(100px) rotate(10deg) scale(0.9);
        transform-origin: center bottom;
        opacity: 0;
    }

    .spin-out-leave-to {
        transform: translateX(-100px) rotate(-10deg) scale(0.9);
        transform-origin: center bottom;
        opacity: 0;
    }

    //cross dissolve transition
    .cross-dissolve-enter-active {
        transition: all 1.5s ease;
        transition-delay: 0.5s;
    }

    .cross-dissolve-leave-active {
        transition: all 0.7s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .cross-dissolve-enter-from
    /* .slide-fade-leave-active below version 2.1.8 */ {
        /*transform: translateX(100px) rotate(10deg) scale(0.9);
    transform-origin: center bottom;*/
        opacity: 0;
    }

    .cross-dissolve-leave-to {
        /*transform: translateX(-100px) rotate(-10deg) scale(0.9);
    transform-origin: center bottom;*/
        opacity: 0;
    }
</style>

<style lang="scss">
    .excerpt p {
        margin-top: 1rem;
        margin-bottom: 0px;
        line-height: 1.2;
    }
</style>
